import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started-"
    }}>{`Getting started 🔥`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p><em parentName="p">{`5 mins read`}</em></p>
    <iframe src="https://giphy.com/embed/SvFocn0wNMx0iv2rYz" width="100" height="100" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "prerequisite"
    }}>{`Prerequisite`}</h2>
    <p>{`Install `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` and have it running in your application.`}</p>
    <h2 {...{
      "id": "1-install-uireact-packages-️"
    }}>{`1. Install @uireact packages 🛠️`}</h2>
    <p>{`There are 2 main components in the library:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`@uireact/foundation`}</inlineCode>{`, all foundation code, utils, theming, breakpoints, etc...`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`@uireact/view`}</inlineCode>{`, the app wrapper that creates global styles and providers such as dialogs.`}</li>
    </ol>
    <p>{`Once the peers are installed you can go ahead and install the @uireact packages, let's start with the most important ones:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm i -S @uireact/foundation @uireact/view
`}</code></pre>
    <h2 {...{
      "id": "2-create-your-theme-"
    }}>{`2. Create your theme ✨`}</h2>
    <p>{`@uireact provides with a `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/themes/default-theme.ts"
      }}>{`default theme`}</a>{` so you can either use that or create your own. If you like the default theme then you can skip this step.`}</p>
    <p>{`If you want to build a custom theme visit `}<a parentName="p" {...{
        "href": "./create-theme"
      }}>{`Create your own theme`}</a>{` to learn more about it.`}</p>
    <iframe src="https://giphy.com/embed/xTg8B548YKZjBdNxHG" width="200" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "3-set-up-uiview-"
    }}>{`3. Set up UiView 🤖`}</h2>
    <p>{`This is basically wrapping your app in the `}<inlineCode parentName="p">{`UiView`}</inlineCode>{` component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';

import { DefaultTheme } from '@uireact/foundation';
import { UiView } from '@uireact/view';

export const MyAppView = () => (
  <UiView theme={DefaultTheme} selectedTheme={ThemeColor.light}>
    <p>View content</p>
  </UiView>
);
`}</code></pre>
    <p>{`PRO tips:`}</p>
    <ul>
      <li parentName="ul">{`You can have the selected theme in a react state so you can have a config your UI for the user to swap between the colorations.`}</li>
    </ul>
    <span style={{
      "fontSize": "14px"
    }}>At this point you deserve a cup of coffee ☕️</span>
    <br />
    <iframe src="https://giphy.com/embed/L8K62iTDkzGX6" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "-create-magic-"
    }}>{`🏁 Create magic 🚀`}</h2>
    <p>{`You are ready to start building your amazing projects!`}</p>
    <p>{`Each available package has its own doc file in the navbar 👈 so you can read about each one there.`}</p>
    <p>{`If you find any issue, want to collaborate or ask for a specific feature you can fill an `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/issues"
      }}>{`issue`}</a>{` and we'll try to review the topic as soon as possible ✍️`}</p>
    <iframe src="https://giphy.com/embed/XWGEbRTdqD7k4J8STq" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <iframe src="https://giphy.com/embed/143vPc6b08locw" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "usage-with-frameworks"
    }}>{`Usage with frameworks`}</h2>
    <h3 {...{
      "id": "vite"
    }}>{`Vite`}</h3>
    <ol>
      <li parentName="ol">{`Create the app`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm create vite@latest
`}</code></pre>
    <p>{`Follow the steps on the CLI, make sure to select react as the UI framework, and Typescript is highly recommended`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install @uireact packages`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` npm install --save @uireact/foundation @uireact/view @uireact/button
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Run the Application`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run dev
`}</code></pre>
    <ol>
      <li parentName="ol">{`Modify the /src/App.tsx file to wrap the application with the provider and import a component`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useState } from 'react';
import './App.css';
import { DefaultTheme, ThemeColor } from '@uireact/foundation';
import { UiView } from '@uireact/view';
import { UiButton } from '@uireact/button';
function App() {
  const [count, setCount] = useState(0);
  return (
    <UiView theme={DefaultTheme} selectedTheme={ThemeColor.light}>
      <h1>Vite + uireact</h1>
      <div className="card">
        <UiButton theme="negative" onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </UiButton>
      </div>
    </UiView>
  );
}
export default App;
`}</code></pre>
    <p>{`a full example can be find here `}<a parentName="p" {...{
        "href": "../examples/vite-ui-react/"
      }}>{`here`}</a></p>
    <h3 {...{
      "id": "nextjs"
    }}>{`NextJS`}</h3>
    <ol>
      <li parentName="ol">{`Create the app`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx create-next-app@latest
`}</code></pre>
    <p>{`Follow the steps on the CLI, Typescript is highly recommended`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install @uireact packages`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` npm install --save @uireact/foundation @uireact/view @uireact/button
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Run the Application`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run dev
`}</code></pre>
    <ol>
      <li parentName="ol">{`Modify the src/app/page.tsx file to wrap the application with the provider and import a component`}</li>
    </ol>
    <p>{`note: Don't forget to add "use client" directive since next by default will make the component a server component, and server components can't use useState nor can leverage the createContext api needed on uireact library.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`'use client';
import { useState } from 'react';
import { DefaultTheme, ThemeColor } from '@uireact/foundation';
import { UiView } from '@uireact/view';
import { UiButton } from '@uireact/button';

export default function Home() {
  const [count, setCount] = useState(0);
  return (
    <UiView theme={DefaultTheme} selectedTheme={ThemeColor.light}>
      <main className="flex min-h-screen flex-col items-center justify-between p-24">
        <UiButton theme="negative" onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </UiButton>
      </main>
    </UiView>
  );
}
`}</code></pre>
    <p>{`a full example can be find here `}<a parentName="p" {...{
        "href": "../examples/nextjs-example/"
      }}>{`here`}</a></p>
    <h3 {...{
      "id": "remix"
    }}>{`Remix`}</h3>
    <ol>
      <li parentName="ol">{`Create the app`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx create-remix@latest
`}</code></pre>
    <p>{`Follow the steps on the CLI, select just the basics, and Remix server, Typescript is highly recommended`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install @uireact packages`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` npm install --save @uireact/foundation @uireact/view @uireact/button

`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Run the Application`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run dev
`}</code></pre>
    <ol>
      <li parentName="ol">{`Modify the app/routes/`}{`_`}{`index.tsx file to wrap the application with the provider and import a component`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import type { V2_MetaFunction } from '@remix-run/node';
import { useState } from 'react';
import { DefaultTheme, ThemeColor } from '@uireact/foundation';
import { UiView } from '@uireact/view';
import { UiButton } from '@uireact/button';
export const meta: V2_MetaFunction = () => {
  return [{ title: 'New Remix App' }, { name: 'description', content: 'Welcome to Remix! with uireact' }];
};
export default function Index() {
  const [count, setCount] = useState(0);
  return (
    <UiView theme={DefaultTheme} selectedTheme={ThemeColor.light}>
      <div style={{ fontFamily: 'system-ui, sans-serif', lineHeight: '1.8' }}>
        <UiButton theme="primary" onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </UiButton>
      </div>
    </UiView>
  );
}
`}</code></pre>
    <p>{`a full example can be find here `}<a parentName="p" {...{
        "href": "../examples/remix-ui-react/"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      